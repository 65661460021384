import about from "./views/about.cs";
import addItem from "./views/addItem.cs";
import contacts from "./views/contacts.cs";
import ebay from "./views/ebay.cs";
import userSettings from "./views/userSettings.cs";
import publicHomepage from "./views/publicHomepage.cs";
import itemDetail from "./views/itemDetail.cs";
import landingPages from "./views/landingPages.cs";
import magazine from "./views/magazine.cs";
import accountSetup from "./views/accountSetup.cs";
import general from "./general/general.cs";
import form from "./general/form.cs";
import appLayout from "./general/appLayout.cs";
import publicLayout from "./general/publicLayout.cs";
import collection from "./general/collection.cs";
import catalogue from "./views/catalogue.cs";
import publicItemsList from "./views/publicItemsList.cs";
import dashboard from "./views/dashboard.cs";
import chat from "./views/chat.cs";
import gdpr from "./views/gdpr.cs";
import itemLike from "./components/itemLike.cs";
import auth from "./views/auth.cs";
import publicItemsFilter from "./components/publicItemsFilter.cs";
import profile from "./views/profile.cs";
import error404 from "./views/error404.cs";
import gallery from "./components/gallery.cs";
import collectors from "./views/collectors.cs";
import filter from "@/locales/components/filter.cs";
import collectorsFilter from "@/locales/components/collectorsFilter.cs";
import cookieConsent from "@/locales/components/cookieConsent.cs";
import dropdown from "@/locales/components/dropdown.cs";

export default {
  ...publicLayout,
  ...appLayout,
  ...form,
  ...general,
  ...collection,
  views: {
    ...about,
    ...addItem,
    ...auth,
    ...catalogue,
    ...contacts,
    ...collectors,
    ...dashboard,
    ...ebay,
    ...error404,
    ...chat,
    ...itemDetail,
    ...landingPages,
    ...accountSetup,
    ...magazine,
    ...profile,
    ...publicHomepage,
    ...publicItemsList,
    ...userSettings,
    ...gdpr,
  },
  components: {
    ...collectorsFilter,
    ...cookieConsent,
    ...filter,
    ...gallery,
    ...itemLike,
    ...publicItemsFilter,
    ...dropdown,
  },
};
