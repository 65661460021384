export default {
  itemDetail: {
    loading: "Načítavam predmet...",
    itemToMarketplaceText: "<strong>Chcel by si svoj predmet predať?</strong> Vyplň si základné predajné parametre a vystav ho na trhovisko",
    itemToMarketplaceCTA: "Vystaviť na trhovisko",
    params: {
      nameEditBtnTitle: "Zmeniť názov",
      privateNoteLabel: "Súkromná poznámka",
      publicNoteLabel: "Verejná poznámka",
      noteLabel: "Poznámka",
      isItemPublicLabel: "Verejný predmet",
      isItemPublicTooltip: "Ak nastavíš predmet ako verejný, ostatní zberatelia ho uvidia v tvojom profile. Ak ho nastavíš ako neverejný, uvidíš ho len ty vo svojej zbierke.",
      acquireSource: "Odkiaľ získané",
      whereStored: "Kde skladujem",
      publicOfferedPrice: "Predajná cena",
      negotiablePrice: "Cena dohodou",
      publicPieces: "Kusy na trhovisku",
      acquireDate: "Dátum obstarania",
      acquirePrice: "Obstarávacia cena",
      currentPrice: "Odhadovaná cena",
      tags: "Tagy",
      tagsHelp: "Doplň si k predmetu tagy, podľa ktorých môžeš vo zbierke filtrovať predmety.",
      tagsShowAll: "zobraziť všetko",
      tagsShowLess: "zobraziť menej",
      tagRemoved: "Tag { tag } odstránený.",
      tagsSave: "Uložiť",
      pieces: "Počet kusov",
      owner: "Zberateľ",
      legoSet: {
        theme: "Téma",
        number: "Číslo",
        pieces: "Počet kociek",
        year: "Rok vydania",
        size: "Rozmery krabice",
        weight: "Hmotnosť",
      },
      legoMinifig: {
        number: "Číslo",
        pieces: "Počet kociek",
      },
      lockedTooltip: "Parameter tejto položky je pevne stanovený výrobcom a nemožno ho upravovať.",
      saveInProgress: "Ukladám",
      saveBtn: "Uložiť",
      saved: "Uložené",
      saveFailed: "Nepodarilo sa uložiť.",
    },
    errors: {
      noteTooLong: "Poznámka môže byť najviac { maxLength } znakov dlhá.",
      whereStoredTooLong: '"Kde skladujem" môže byť najviac { maxLength } znakov dlhé.',
      whereStoredTooShort: '"Kde skladujem" musí byť aspoň { minLength } znakov dlhé.',
      acquisitionSourceTooLong: '"Odkiaľ získané" môže byť najviac { maxLength } znakov dlhé.',
      acquisitionSourceTooShort: '"Odkiaľ získané" musí byť aspoň { minLength } znakov dlhé.',
      nameTooLong: "Názov alebo ID predmetu nesmie byť dlhšie ako { maxLength } znakov.",
      nameTooShort: "Názov alebo ID predmetu sa musí skladať z aspoň { minLength } znaku.",
      itemsListNotFound: "Bohužiaľ, hľadané predmety neboli nájdené.",
      publicOfferedPriceTooLow: "Príliš nízka hodnota.",
      publicOfferedPriceTooHigh: "Hodnota nesmie byť vyššia ako { max }.",
      publicPiecesTooLow: "Zadajte minimálne jeden kus",
      publicPiecesTooHigh: "Hodnota nesmie byť vyššia ako { max }.",
      piecesTooLow: "Zadajte minimálne jeden kus",
      piecesTooHigh: "Hodnota nesmie byť vyššia ako { max }.",
      acquirePriceTooLow: "Obstarávacia cena nesmie byť nižšia ako { min }",
      acquirePriceTooHigh: "Obstarávacia cena nesmie byť vyššia ako { max }.",
      currentPriceTooLow: "Orientacná cena nesmie byť nižšia ako { min }",
      currentPriceTooHigh: "Orientacná cena nesmie byť vyššia ako { max }.",
      wrongAcquireDate: "Vyplň platné dátum.",
      itemLoadError: "Predmet sa nepodarilo načítať.",
      tagTooShort: "Vyplň aspoň { minLength } znaky.",
      tagTooLong: "Vyplň najviac {  } znakov.",
    },
    messages: {
      itemAddedIntoList: "Zverejnil si predmet.",
      itemRemovedFromList: "Predmet si skryl pred svetom. Vidíš ho už len ty.",
      tagAdded: "Tag { tag } pridaný.",
    },
    actions: {
      sendMessage: "Mám záujem",
    },
    chart: {
      heading: "Vývoj orientačnej ceny",
    },
    gallery: {
      added: "Fotka pridaná.",
      removed: "Zmazal(a) si fotku.",
      coverSet: "Nastavené ako cover.",
    },
  },
};
