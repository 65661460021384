import { useBreadcrumbsStore } from "@/store";
import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

export const setBreadcrumbs = async (route: RouteLocationNormalized, _: RouteLocationNormalized, next: NavigationGuardNext) => {
  const breadcrumbs = route.meta.breadcrumbs;
  if (breadcrumbs) {
    const breadcrumbsStore = useBreadcrumbsStore();
    if (breadcrumbs.length > 0) {
      breadcrumbsStore.set(breadcrumbs);
    } else {
      breadcrumbsStore.clear();
    }
  }

  return next();
};
