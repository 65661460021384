<template>
  <ClickableComponent v-bind="attrs" :class="cssClasses">
    <div v-if="loading" class="btn-new__loader-wrapper">
      <LoadingSmall color="var(--green50)" />
    </div>

    <Icon v-if="icon !== undefined && !loading" :icon="icon" class="btn-new__icon" />

    <template v-if="loading && loadingText">
      {{ loadingText }}
    </template>
    <slot v-else></slot>
  </ClickableComponent>
</template>

<script lang="ts">
import { computed, PropType } from "vue";
import Icon, { iconProperty } from "./Icon.vue";
import ClickableComponent from "./ClickableComponent.vue";
import LoadingSmall from "../LoadingSmall.vue";

export enum Variant {
  primary,
  secondary,
  tertiary,
}

export default {
  components: {
    Icon,
    ClickableComponent,
    LoadingSmall,
  },
  props: {
    icon: { ...iconProperty, required: false },
    variant: {
      type: Number as PropType<Variant>,
      default: () => Variant.primary,
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
    loadingText: {
      type: String,
      default: () => "",
    },
    isDisabled: Boolean,
    isFullWidth: Boolean,
  },
  setup(props, { attrs }) {
    const cssClasses = computed(() => {
      return {
        "btn-new": true,
        "btn-new--primary": props.variant === Variant.primary,
        "btn-new--secondary": props.variant === Variant.secondary,
        "btn-new--tertiary": props.variant === Variant.tertiary,
        "btn-new--with-loader": props.loading,
        "btn-new--is-disabled": props.isDisabled,
        "btn-new--is-full-width": props.isFullWidth === true,
      };
    });

    return {
      attrs,
      Variant,
      cssClasses,
    };
  },
};
</script>

<style lang="scss" scoped>
.btn-new {
  height: var(--controlHeight);
  min-width: 110px;
  padding: 0 var(--spacing3x);
  transition: background 0.2s ease, color 0.2s ease;
  cursor: pointer;
  border-radius: var(--controlRadius);
  font: var(--controlFontSize) / 1.4 var(--font-family);
  color: var(--gray900);
  text-decoration: none;
  isolation: isolate;
  @include outline;

  // Element-plus generated html
  &:deep(> span) {
    gap: calc(var(--spacing3x) / 2);
  }
  &:hover {
    text-decoration: none;
  }
  &--primary {
    border: 0;
    background-position: 100% 0;
    background-size: 202% 100%; // 202 to prevent some browsers buggy rendering of bg around rounded corners
    background-color: transparent;
    background-image: linear-gradient(to right, var(--green950) 49%, var(--greenPrimary) 50%);
    color: var(--white);
    &:hover {
      background-position: 0 0;
    }
  }
  &--secondary {
    background-color: var(--grayTransparent50);
    color: var(--gray950);
    border: 0;
    &:hover {
      background-color: var(--grayTransparent100);
    }
  }
  &--tertiary {
    border: 0;
    background-color: rgba(0, 0, 0, 0);
    &:hover {
      background-color: var(--grayTransparent50);
      color: var(--gray950);
    }
  }
  &--with-loader {
    padding: 0 var(--spacing1x);
  }
  &--is-disabled {
    background-image: linear-gradient(to right, var(--gray300) 49%, var(--gray300) 50%);
    background-color: var(--gray300);
    pointer-events: none;
  }
  &--is-full-width {
    width: 100%;
  }
  &__icon {
    width: var(--controlIconSize);
    height: var(--controlIconSize);
  }
  &__loader-wrapper {
    position: relative;
    width: 12px;
    height: 12px;
  }
}
</style>
