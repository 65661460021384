export default {
  dashboard: {
    myCollection: {
      heading: "@:views.myCollection.heading",
      cta: "Zobraziť zbierku",
    },
    publicItems: {
      cta: "Viac predmetov",
      errorMsg: "Predmety sa nepodarilo načítať.",
      errorRetryBtn: "Skús znova",
      tabs: {
        newest: "Najnovšie",
        forYou: "Výber pre teba",
      },
      forYou: {
        popupText: "Tvoje obľúbené kategórie nám pomôžu lepšie pochopiť, o aké typy predmetov máš záujem.",
        popupBtn: "Nastaviť obľúbené kategórie",
        infoText: "Založené na tvojich obľúbených kategóriách",
        emptyMsg: "Pre tvoje obľúbené kategórie sme bohužiaľ nenašli žiadny zaujímavý predmet.",
        emptyBtn: "Zmeniť obľúbené kategórie",
      },
    },
    ebay: {
      heading: "@:views.ebay.heading",
      introText: {
        desktop: "@:views.ebay.introText",
        phone: "Zisti, za koľko sa predávajú zberateľské predmety vo svete (eBay).",
      },
    },
    magazine: {
      heading: "@:views.magazine.heading",
      cta: "Zobraziť viac",
      errorMsg: "Články sa nepodarilo načítať.",
      errorRetryBtn: "Skús znova",
    },
  },
};
