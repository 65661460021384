export default {
  catalogue: {
    breadcrumbs: "Databáza",
    intro: {
      heading: "Databáza",
      text: "Chceš nájsť informácie o určitom predmete? Máme tu pre teba ucelené databázy, ktoré pravidelne aktualizujeme. Môžeš si tak dohľadať cez filter alebo vyhľadávač informácie o konkrétnom predmete aj s jeho parametrami, priamo z databáz predmety pridávať do svojej zbierky, a to aj so všetkými údajmi.",
      loading: "Načítavam kategórie…",
      workInProgress: "pripravujeme",
    },
    category: {
      title: "Databáza: { categoryName }",
      heading: "Databáza: { categoryName }",
      headingResults: "Výsledok hľadania",
    },
    itemDetail: {
      addToCollection: "Pridať do zbierky",
    },
    itemBox: {
      id: "ID",
      price: "Cena",
    },
    filter: {
      searchButton: "Vyhľadať",
      fulltextPlaceholder: "hľadať...",
      fulltextTooShort: "Vyplňte aspoň {{ minLength }} znaky.",
      submit: "Vyhľadať",
      boardGames: {
        name: "Názov",
        developers: "Autori",
        languages: "Jazyk",
      },
      vinyls: {
        name: "Názov / Cat# / Kód",
        interpret: "Interpret",
        year: "Rok vydania",
        country: "Krajina",
        format: "Formát",
      },
      legoSets: {
        name: "Názov predmetu / ID",
        set: "Set",
        theme: "Téma",
        year: "Rok vydania",
      },
      legoMinifigs: {
        fulltext: "Nájsť figúrku",
      },
      watches: {
        name: "Nájsť hodinky",
        brand: "Značka",
      },
    },
    table: {
      empty: "Bohužiaľ, tvojmu filtru neodpovedá žiadna položka.",
      boardGames: {
        developer: "Autor",
        language: "Jazyk",
        year: "Rok vydania",
      },
      legoSets: {
        id: "ID",
        price: "Orientácia cena",
        year: "Rok vydania",
      },
      legoMinifigs: {
        id: "Číslo",
        price: "Orientácia cena",
        piecesCount: "Počet dielikov",
      },
      watches: {
        reference: "Referencia",
        brand: "Značka",
        model: "Model",
      },
      vinyls: {
        name: "Cat# / Kód",
        interpret: "Interpret",
        year: "Rok vydania",
      },
    },
  },
};
