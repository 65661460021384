export enum Events {
  showTextOverlay,
  hideTextOverlay,

  toastSuccess,
  toastError,
  openGallery,

  PortfolioItemHardRemoved,
  collectionParamsChanged,
  collectionParamsSynchronize,

  dropdownOpen,
  keyEscape,
  confirm,
  showFeedbackDialog,

  userUpdated,

  openAuthDialog,
  openAddItemDialog,
}
