/**
 * Types shared inside of user module.
 */

import { CurrencyId } from "@/services/currencies";
import { LanguageId } from "@/services/translation";
import { UserFlag } from "@/types";

export type UpdateUserSettingsData = Partial<{
  nickname: string;
  nicknameDaysTillNew: number;
  publicFullName: string;
  publicEmail: string;
  publicPhone: string;
  publicUrlFacebook: string;
  publicUrlInstagram: string;
  publicUrlTikTok: string;
  publicUrlPinterest: string;
  publicUrlWeb: string;
  publicBioDescription: string;
  flags: Array<keyof typeof UserFlag>;
  isChatEmailNotificationEnabled: boolean;
  displayLanguageId: LanguageId;
  displayCurrencyId: CurrencyId;
}>;

export enum AccountType {
  common = "common",
  facebook = "facebook",
  google = "google",
}

export enum Role {
  admin = "admin",
  collector = "collector",
}

export type RawUserEssentialsData = {
  id: number;
  email: string;
  name: string;
  image: string;
  showPrices: number;
  type: AccountType;
  role: Role;
};

export enum LoginProvider {
  google = AccountType.google,
  facebook = AccountType.facebook,
}

export enum ThirdPartyLoginStatus { // API response codes
  ok = 1,
  differentAccountType = 2,
  accountInactive = 3,
  wrongData = 4,
}

type ApiResponse_ThirdPartyLoginAuthentication_ok = {
  status: ThirdPartyLoginStatus.ok;
  token: string;
  user: RawUserEssentialsData;
  isFirstLogin: boolean;
};

type ApiResponse_ThirdPartyLoginAuthentication_error = {
  status: ThirdPartyLoginStatus.accountInactive | ThirdPartyLoginStatus.differentAccountType | ThirdPartyLoginStatus.wrongData;
  message: string;
};

export type ApiResponse_ThirdPartyLoginAuthentication = ApiResponse_ThirdPartyLoginAuthentication_ok | ApiResponse_ThirdPartyLoginAuthentication_error;
