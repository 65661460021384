import { getLocalizationObject } from "@/router/helpers";
import { useCookieConsent } from "@/services/cookiesConsent";
import { FallbackLanguageCode } from "@/services/translation";
import { RouteLocationNormalized } from "vue-router";

export const localizeCookieConsent = async (route: RouteLocationNormalized) => {
  const localization = getLocalizationObject(route);
  const { cookieConsent } = useCookieConsent();
  cookieConsent.setLanguage(localization?.language ?? FallbackLanguageCode);
};
