import { Breadcrumb } from "@/composables";
import { LandingPageSetup } from "@/services/landingPages";
import "vue-router";

export enum Layout {
  App,
  Public,
  LockingState,
}

declare module "vue-router" {
  interface RouteMeta {
    public?: boolean;
    layout: Layout;
    layoutLoggedIn?: Layout; // In case logged-in user should see different layout.
    title?: () => string;
    breadcrumbs?: Breadcrumb[];

    /**
     * New-design pages have additional spacing at the top, while older pages do not.
     * Use this property to add the required spacing temporarily, until all pages are unified.
     * Once unification is complete, remove this property and integrate the spacing directly into the layout.
     */
    addTopSpacing?: boolean;

    /**
     * Homepage and other category-specific landing pages.
     * Object with appropriate texts/images/...
     */
    landingPageDataFactory?: () => LandingPageSetup;

    /**
     * If a logged-in user visits this route, should they be redirected elsewhere?
     * This is useful when we want to disable access to a public page for logged-in users
     * (e.g., redirect from the public homepage to the dashboard).
     */
    redirectWhenLoggedIn?: RouteLocationAsRelativeGeneric;

    /**
     * Use this option to prevent the page from jumping to the top when URL changes but we're still
     * staying on the same page. Useful when updating URL after some on-page filter is changed -> we
     * don't want to scroll to top whenever the filter is changed.
     */
    preventScrollToTop?: boolean;

    allowNoCountryCode?: boolean;
  }
}
