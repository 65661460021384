export default {
  collection: {
    makeItemPublicDialog: {
      header: "Veřejný předmět",
      text: "Tvůj předmět se stane nově <strong>stane veřejně dostupným</strong>. Ostatní uživatele jej budou moci dohledat a uvidí jej také na tvém Veřejném profilu.",
      confirm: "Souhlasím",
      confirmMessage: "Předmět zveřejněn.",
    },
    makeItemPrivateDialog: {
      header: "Soukromý předmět",
      text: "Tvůj předmět již <strong>nebude veřejně dostupný</strong>. Ostatní sběratelé jej neuvidí ani na tvém profilu ani v jiných sekcích.",
      confirm: "Souhlasím",
      confirmMessage: "Předmět odzveřejněn.",
    },
    itemList: {
      owner: "Sběratel",
      pieces: "Množství",
      piecesUnit: "ks",
      offeringFor: "Cena",
      noNote: "Bez poznámky",
      detailButton: "Detail předmětu",
      visibilityLabel: "Zobrazení předmětu",
      publishDate: "Vystaveno",
    },
    itemsTable: {
      note: {
        label: "Poznámka",
        empty: "Prázdná poznámka",
      },
      delete: "Smazat",
      tags: {
        nothingSelectedLabel: "# Všechny tagy",
        somethingSelectedLabel: "# Počet vybraných: ",
        noOptionsAvailableLabel: "Žádné dostupné tagy.",
        searchInputPlaceholder: "Hledat tag",
      },
    },
    stats: {
      loading: "načítám...",
      labelValue: "Hodnota sbírky",
      labelItemsCount: "Počet předmětů",
    },
  },
};
