import { router } from "@/router";

export function getUrlParamAsString(paramName: string): string {
  const route = router.currentRoute.value;
  const value = route.params[paramName];

  if (value === undefined) {
    throw new Error("Parameter '" + paramName + "' not found in URL.");
  }

  if (typeof value !== "string") {
    throw new Error("Parameter '" + paramName + "' is not a string.");
  }

  return value;
}

export function getUrlQueryAsString(paramName: string): string {
  const route = router.currentRoute.value;
  const value = route.query[paramName];

  if (value === undefined) {
    throw new Error("Query parameter '" + paramName + "' not found in URL.");
  }

  if (typeof value !== "string") {
    throw new Error("Query parameter '" + paramName + "' is not a string.");
  }

  return value;
}

export function getUrlParamAsNumber(paramName: string): number {
  const route = router.currentRoute.value;
  const value = route.params[paramName];

  if (value === undefined) {
    throw new Error("Parameter '" + paramName + "' not found in URL.");
  }

  if (typeof value !== "string") {
    throw new Error("DEV: Parameter '" + paramName + "' is not a string.");
  }

  if (isNaN(+value)) {
    throw new Error("Parameter '" + paramName + "' is not a number.");
  }

  return +value;
}

export function getUrlQueryAsNumber(paramName: string): number {
  const route = router.currentRoute.value;
  const value = route.query[paramName];

  if (value === undefined) {
    throw new Error("Parameter '" + paramName + "' not found in URL.");
  }

  if (typeof value !== "string") {
    throw new Error("DEV: Parameter '" + paramName + "' is not a string.");
  }

  if (isNaN(+value)) {
    throw new Error("Parameter '" + paramName + "' is not a number.");
  }

  return +value;
}

/** @todo Rename to getUrlQuery...(). It's not working with params, it's working with query! */
export function getUrlParamAsNumberArray(paramName: string): number[] {
  const route = router.currentRoute.value;
  const value = route.query[paramName];

  if (Array.isArray(value)) {
    return value.map(Number);
  } else if (value !== undefined) {
    return [Number(value)];
  } else {
    throw new Error("DEV: Parameter '" + paramName + "' is not a number array.");
  }
}

/** @todo Rename to getUrlQuery...(). It's not working with params, it's working with query! */
export function getUrlParamAsBool(paramName: string, allowZeroAndOne = false): boolean {
  const route = router.currentRoute.value;
  const value = route.query[paramName];

  if (value === undefined) {
    throw new Error("Parameter '" + paramName + "' not found in URL.");
  }

  if (typeof value !== "string") {
    throw new Error("DEV: Parameter '" + paramName + "' is not a string.");
  }

  if (value === "true") {
    return true;
  } else if (value === "false") {
    return false;
  }

  if (allowZeroAndOne) {
    if (value === "1") {
      return true;
    } else if (value === "0") {
      return false;
    }
  }

  throw new Error("URL parameter '" + paramName + "' has invalid value.");
}
