import { getUserForRouter, redirectToAuth } from "@/router/helpers";
import { TokenNotValidException } from "@/services/user";
import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

export const handleInvalidTokenFound = async (route: RouteLocationNormalized, _: RouteLocationNormalized, next: NavigationGuardNext) => {
  try {
    await getUserForRouter();
    return next();
  } catch (e: unknown) {
    if (e instanceof TokenNotValidException) {
      return redirectToAuth(route, next);
    } else {
      throw e;
    }
  }
};
