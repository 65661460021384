import { getUserForRouter, isRouteAccountSetup } from "@/router/helpers";
import { createAccountSetupLink } from "@/router/routes";
import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

export const handleAccountCompletionNeeded = async (route: RouteLocationNormalized, _: RouteLocationNormalized, next: NavigationGuardNext) => {
  if (isRouteAccountSetup(route)) {
    return next();
  }

  const user = await getUserForRouter();
  const isAccountCompletitionNeeded = user && user.data === undefined;
  if (isAccountCompletitionNeeded) {
    return next(createAccountSetupLink());
  } else {
    return next();
  }
};
