export const MapHelpers = {
  getKeyByValue: <K, V>(map: Map<K, V>, value: V): K | undefined => {
    for (const [key, val] of map) {
      if (val === value) {
        return key;
      }
    }
    return undefined;
  },
};

export const revertMap = <K extends string | number | symbol, V extends string | number | symbol>(originalMap: Record<K, V>): Record<V, K> =>
  Object.entries(originalMap).reduce((acc, [key, value]) => {
    acc[value as V] = key as K;
    return acc;
  }, {} as Record<V, K>);
