import { Entity } from "@/services/repositories/Entity";

class ParameterValue extends Entity {
  private column: string;
  private isCustom: boolean;

  constructor(public id: number, private value: string) {
    super(id);
  }

  public getValue(): string {
    return this.value;
  }

  public setColumn(column: string): void {
    this.column = column;
  }

  public getColumn(): string {
    return this.column;
  }

  public setIsCustom(isCustom: boolean): void {
    this.isCustom = isCustom;
  }

  public getIsCustom(): boolean {
    return this.isCustom;
  }
}

export { ParameterValue };
