import { getLocalizationObject } from "@/router/helpers";
import { autodetectCountryCode, setCountry } from "@/services/translation";
import { useAppState } from "@/store/appState";
import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

export const storeLocalizationInApp = async (to: RouteLocationNormalized, _: RouteLocationNormalized, next: NavigationGuardNext) => {
  const localization = getLocalizationObject(to);
  if (localization) {
    setCountry(localization.country);
    useAppState().markLanguageDetected();
    return next();
  } else {
    const url = "/" + autodetectCountryCode() + to.fullPath;
    return next(url);
  }
};
