export default {
  publicLayout: {
    loginButton: "Přihlásit se",
    footer: {
      taxId: "IČ",
      menu: "Menu",
      toTop: "zpět",
      gdprLink: "Zásady ochrany osobních údajů",
      cookiesLink: "Nastavení cookies",
      tacLink: "Všeobecné obchodní podmínky",
    },
    menu: {
      btnTitle: "Menu",
      about: "O nás",
      contacts: "Kontakty",
    },
  },
};
