export default {
  appLayout: {
    menu: {
      dashboard: "Nástenka",
      myCollection: "Moja zbierka",
      collectors: "@:views.collectors.heading",
      magazine: "Magazín",
      catalogue: "Databáza",
    },
    itemsTable: {
      category: "Kategória",
      name: "Názov",
      pieces: "Kusov",
      note: "Poznámka",
      acquirePrice: "Nákupná<br>cena / ks",
      currentPrice: "Orientačná<br>cena / ks",
      itemVisibility: "Verejný predmet",
    },
    topbar: {
      addItemBtn: "Pridať predmet",
      chat: {
        btnTitle: "@:views.chat.heading",
      },
      user: {
        btnTitle: "Používateľské menu",
        settingsBtn: "Môj profil",
        logoutBtn: "Odhlásiť sa",
        logoutMsg: "Úspešne si sa odhlásil. Teším sa nabudúce.",
        pricesLabel: "Skryť ceny",
        pricesHidden: "Ceny sme skryli.",
        pricesVisible: "Ceny sa zobrazujú.",
      },
      menu: {
        btnTitle: "Menu",
        ebay: "Nacenovač",
        gdpr: "@:views.gdpr.heading",
        contacts: "@:publicLayout.menu.contacts",
        about: "@:publicLayout.menu.about",
      },
    },
  },
};
