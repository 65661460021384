export default {
  about: {
    employees: {
      tom: {
        job: "Zakladateľ",
        text: `
          <p>Vždy ma bavilo vytvárať nové projekty. Viac než kedykoľvek predtým som mal chuť urobiť niečo skutočne pre ľudí.
          Niečo, čo im bude robiť skutočnú radosť, alebo to aspoň podporovať. A&nbsp;zbieranie tomu bezpochyby napomáha.
          Sám zbieram všetko možné, začal som na gymnáziu zbierateľskými kartičkami a&nbsp;postupne objavoval ďalšie oblasti.
          Verím, že&nbsp;fyzické predmety majú svoje miesto aj&nbsp;v&nbsp;dnešnej digitálnej dobe. A&nbsp;tak teraz pozerám celé dni do&nbsp;LEGA, hodín atď. so super partiou ľudí... koho by to netešilo? 🙂</p>
        `,
      },
      lukas: {
        job: "Vývoj",
        text: `
          <p>Už ako malý som si našiel obľubu v zbieraní a&nbsp;vystavovaní si vecí, ktoré pre mňa boli vzácne. Od LEGA® na narodeniny, cez angličáky
          za skúšky na umeleckej škole, až po hokejové kartičky a&nbsp;pogy. S&nbsp;postupom veku, pribúdajúcimi financiami a&nbsp;technickým zázemím sa moja zbierka
          rozšírila o&nbsp;staré telefóny a&nbsp;staré PC. Je krásne sa obklopovať vecami a&nbsp;ľuďmi, ktorí robia človeka šťastným. Mojím životným zmyslom je mať
          pod drobnohľadom a&nbsp;pečlivou kontrolou nielen moju zbierku v skrini, ale i&nbsp;vývoj a&nbsp;technické zázemie nášho projektu.</p>
        `,
      },
      robert: {
        job: "Produkt",
        text: `
          <p>Zbieranie ma sprevádza celý život. Od stavebnice Lego® v útlom detstve, kartičiek Magic, Pokémonov a&nbsp;hokejistov na&nbsp;gymnáziu až po umenie v&nbsp;dospelosti.
          Zbieranie je pre mňa hrou, ktorá má vždy pozitívny dopad na moju náladu. Mám ale aj&nbsp;iné vášne. Píšem knihy, venujem sa&nbsp;hudbe a&nbsp;priateľom.</p>
        `,
      },
      radim: {
        job: "Dizajn",
        text: `
          <p>Ten pocit, keď si rozbalíš zásielku s&nbsp;novými kartičkami, to je proste droga. Od&nbsp;hokejistov som sa&nbsp;dostal ku&nbsp;kartovej hre Magic a&nbsp;táto vášeň ma
          drží už viac než dvadsať rokov. Začínam objavovať aj&nbsp;svet hodiniek, ktorým ma nakazili chalani v&nbsp;kancelárii. Keď mi Tom predstavil projekt, vedel som,
          že chcem byť pri tom a&nbsp;spoločne tvoriť svet pre všetkých takých nadšencov a&nbsp;bláznov, ako sme my.
          V rámci projektu mám na&nbsp;starosti grafický dizajn, ktorý som v&nbsp;Brne študoval.<p>
        `,
      },
      filip: {
        job: "Vývoj",
        text: `
          <p>Kto by si nepamätal zbieranie hokejových kariet na&nbsp;prelome tisícročia? Vtedy sme sedávali na&nbsp;obrubníku a&nbsp;menili tri Hejduky za jedného Jágra. Chodili sme za cudzími ľuďmi,
          o&nbsp;ktorých sme sa dopočuli, že majú album, a&nbsp;snažili sme sa od nich získať ďalších Jágrov. To bol zberateľský punk!</p>
          <p>Láska k&nbsp;hokejovým kartičkám mi zostala dodnes a&nbsp;já mám radosť, že prostredníctvom Colectia môžem zbierateľom pomôcť sa opäť stretávať na pomyselnom obrubníku (hoci len virtuálnom).</p>
          <p>Kromě hokejistov mi však robí radosť aj&nbsp;florbal alebo napríklad akvaristika.</p>
        `,
      },
      pavla: {
        job: "Sociálne siete",
        text: `
          <p>Úprimne? Som tak trochu zbierateľ amatér. Keď som bola malá, viedlo u&nbsp;mňa na plné čiare Lego® (u&nbsp;koho tiež nie) a&nbsp;milovala som Čtyřlístek.
          Teraz som k&nbsp;Legu pridala sneakers, streetwear, prichádzam na&nbsp;chuť Pokémonom a&nbsp;pozerám po&nbsp;hodinkách. Čo môže znieť ako zvláštna kombinácia,
          ale práve preto ma práca v&nbsp;Colectiu baví - rozširuje mi moje zbierateľské obzory.</p>
        `,
      },
    },
    heading: "O nás",
    textIntro1: "Veríme, že v&nbsp;živote je tiež potrebné si&nbsp;robiť radosť. Rozhodli sme sa&nbsp;vytvoriť priateľské miesto, kde môžeme zdieľať svoju vášeň. Sme partia nadšencov, ktorí robia, čo ich baví.",
    textIntro2: "Spustením tohto webu sme napísali prvú stranu nášho príbehu. Naším cieľom je&nbsp;vybudovať platformu, kde budú ľudia zabúdať na svoje každodenné starosti, kam sa budú radi vracať a&nbsp;kde nájdu ďalších parťákov so rovnakým nadšením. Vytvoríme miesto, kde sa budú vzájomne podporovať a&nbsp;radiť si. Ponúkneme nástroje a&nbsp;služby, ktoré ľuďom pomôžu so všetkým, čo budú na svojej ceste potrebovať.",
    email: "e-mail:",
    whatMakeMeHappy: "Čo mi robí radosť:",
  },
};
