import { getUserForRouter, isPublic } from "@/router/helpers";
import { useUserStore } from "@/store";
import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

export const handleRedirectForLoggedInUser = async (route: RouteLocationNormalized, _: RouteLocationNormalized, next: NavigationGuardNext) => {
  const user = await getUserForRouter();
  if (user && isPublic(route)) {
    const redirect = route.meta.redirectWhenLoggedIn;
    if (redirect) {
      const userStore = useUserStore();
      const newRoute = {
        ...redirect,
        params: {
          ...redirect.params,
          countryCode: userStore.getCountryCode(),
        },
      };
      return next(newRoute);
    }
  }

  return next();
};
