import { getUserForRouter, isPublic, redirectToAuth } from "@/router/helpers";
import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

export const handleLogin = async (route: RouteLocationNormalized, _: RouteLocationNormalized, next: NavigationGuardNext) => {
  const user = await getUserForRouter();
  const requireLogin = user === undefined && !isPublic(route);
  if (requireLogin) {
    return redirectToAuth(route, next);
  } else {
    return next();
  }
};
