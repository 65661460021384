import { AvailableCategory, AvailableTag } from "@/services/portfolio/collectionApi";
import { ItemStatusUrlString, UrlStringToItemStatus } from "@/services/portfolio/url";
import { CategoriesAll, ItemStatus, ValueAllCategories } from "@/services/repositories/collection/Collection";
import { CategoryId, OnlyPublicProperties } from "@/types";
import { getEnumKeyByValue, getUrlParamAsNumberArray, getUrlParamAsString, getUrlQueryAsNumber, getUrlQueryAsString, isStringInEnum } from "@/utilities";
import { InjectionKey, Ref } from "vue";

export type injectionKeyType = Ref<OnlyPublicProperties<Filter>>;
export const injectionKey = Symbol() as InjectionKey<injectionKeyType>;

export enum SortBy {
  NewestFirst = "newestFirst",
  OldestFirst = "oldestFirst",
}

type CategoryValue = CategoryId | CategoriesAll;
type ItemStatusValue = ItemStatus | undefined;
type TagIdsValue = number[] | undefined;

type Model = {
  categoryId: CategoryValue;
  sortOrder: SortBy;
  page: number;
  perPage: number;
  itemStatus: ItemStatusValue;
  tagIds: TagIdsValue;
};

export class Filter {
  private _categoryId: CategoryValue;
  private _sortOrder: SortBy;
  private _page: number;
  private _perPage: number;
  private _itemStatus: ItemStatusValue;
  private _tagids: TagIdsValue;

  // Preset/default values
  private defaults: Model;

  // What to set when reset() is done
  private emptyState: Model;

  private _availableCategories: AvailableCategory[] = [];
  private _availableTags: AvailableTag[] = [];

  private _activeCount = 0;

  constructor(customDefaults: Partial<Model> = {}, emptyState: Partial<Model> = {}) {
    const defaults = {
      categoryId: ValueAllCategories,
      sortOrder: SortBy.NewestFirst,
      page: 0,
      perPage: 20, // Defined by product
      itemStatus: undefined,
      tagIds: undefined,
    };

    this.defaults = {
      ...defaults,
      ...customDefaults,
    };

    const receivedEmptyStateWithoutUndefined = Object.fromEntries(Object.entries(emptyState).filter(([_, value]) => value !== undefined));
    this.emptyState = {
      ...defaults,
      ...receivedEmptyStateWithoutUndefined,
    };

    this.reset();
  }

  public fillFromUrl(): Filter {
    this.reset();

    try {
      this.categoryId = getUrlQueryAsNumber("categoryId");
    } catch (e: unknown) {} /* eslint-disable-line */ // Keep value set in reset() call

    try {
      const urlItemStatus = getUrlParamAsString("itemStatus");
      if (isStringInEnum(urlItemStatus, ItemStatusUrlString)) {
        const enumString = getEnumKeyByValue(urlItemStatus, ItemStatusUrlString);
        this.itemStatus = UrlStringToItemStatus[enumString];
      }
    } catch (e: unknown) { } /* eslint-disable-line */ // Keep value set in reset() call

    try {
      const sort = getUrlQueryAsString("sortOrder");
      const isValid = isStringInEnum(sort, SortBy);
      if (isValid) {
        this.sortOrder = getEnumKeyByValue(sort, SortBy);
      }
    } catch (e: unknown) { } /* eslint-disable-line */ // Keep value set in reset() call

    try {
      this.tagIds = getUrlParamAsNumberArray("tagIds");
    } catch (e: unknown) { } /* eslint-disable-line */ // Keep value set in reset() call

    try {
      this.page = getUrlQueryAsNumber("page");
    } catch (e: unknown) { } /* eslint-disable-line */ // Keep value set in reset() call

    return this;
  }

  public createApiParams(): Record<string, string | number | number[] | undefined> {
    return {
      categoryId: this.categoryId !== ValueAllCategories ? this.categoryId : undefined,
      sortOrder: this.sortOrder,
      page: this.page,
      perPage: this.perPage,
      itemStatus: this.itemStatus,
      tagIds: this.tagIds,
    };
  }

  public createUrlQuery(): Record<string, string | number | number[] | undefined> {
    const params = this.createApiParams(); // DRY
    params.itemStatus = undefined; // ItemStatus is not in query, it's URL parameter.
    return params;
  }

  public reset(): void {
    this.categoryId = this.defaults.categoryId;
    this.sortOrder = this.defaults.sortOrder;
    this.page = this.defaults.page;
    this.itemStatus = this.defaults.itemStatus;
    this.tagIds = this.defaults.tagIds;
    this._perPage = this.defaults.perPage;
  }

  /** Active filters counting */
  public get activeCount(): number {
    return this._activeCount;
  }

  private recountActiveFilters(): void {
    this._activeCount = 0;
    if (this.categoryId !== this.emptyState.categoryId) {
      this._activeCount++;
    }
    if (this.tagIds && this.tagIds.length > 0) {
      this._activeCount++;
    }
  }

  public set categoryId(newCategoryId: CategoryValue) {
    this._categoryId = newCategoryId;
    this.page = this.defaults.page;
    this.recountActiveFilters();
  }

  public get categoryId(): CategoryValue {
    return this._categoryId;
  }

  public get itemStatus(): ItemStatusValue {
    return this._itemStatus;
  }

  public set itemStatus(wantedItemStatus: ItemStatusValue) {
    this._itemStatus = wantedItemStatus;
    this.defaults.itemStatus = wantedItemStatus; // Current ItemStatus is always default - ignored in reset()
    this.page = this.defaults.page;
    this.recountActiveFilters();
  }

  public set sortOrder(newSortOrder: SortBy) {
    this._sortOrder = newSortOrder;
    this.page = this.defaults.page;
  }

  public get sortOrder(): SortBy {
    return this._sortOrder;
  }

  public set page(newPage: number) {
    this._page = newPage;
  }

  public get page(): number {
    return this._page;
  }

  public set perPage(newPerPage: number) {
    this._perPage = newPerPage;
  }

  public get perPage(): number {
    return this._perPage;
  }

  public set tagIds(newTagIds: TagIdsValue) {
    this._tagids = newTagIds;
    this.recountActiveFilters();
  }

  public get tagIds(): TagIdsValue {
    return this._tagids;
  }

  public set availableCategories(categories: AvailableCategory[]) {
    this._availableCategories = categories;
  }
  public get availableCategories(): AvailableCategory[] {
    return this._availableCategories;
  }

  public set availableTags(tags: AvailableTag[]) {
    this._availableTags = tags;
  }
  public get availableTags(): AvailableTag[] {
    return this._availableTags;
  }
}
