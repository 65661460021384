<template>
  <ClickableComponent v-bind="attrs" :class="cssClasses" :style="inlineStyles" :aria-label="ariaLabel" :title="title">
    <slot>
      <Icon :icon="icon" />
    </slot>
  </ClickableComponent>
</template>

<script lang="ts">
import { computed, PropType, StyleValue } from "vue";
import Icon, { iconProperty } from "./Icon.vue";
import ClickableComponent from "./ClickableComponent.vue";

export enum Variant {
  primary,
  highContrast,
  mediumContrast,
  lowContrast,
  overImage,
}

export default {
  components: {
    Icon,
    ClickableComponent,
  },
  props: {
    icon: iconProperty,
    scale: {
      type: Number,
      required: false,
    },
    variant: {
      type: Number as PropType<Variant>,
      default: () => Variant.overImage,
    },
    showDot: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
  },
  setup(props, { attrs }) {
    const inlineStyles = computed(() => {
      const styles: StyleValue = {};
      if (props.scale !== undefined) {
        styles["--scaleCustom"] = props.scale;
      }
      return styles;
    });

    const cssClasses = computed(() => ({
      "btn-icon": true,
      "btn-icon--primary": props.variant === Variant.primary,
      "btn-icon--medium-contrast": props.variant === Variant.mediumContrast,
      "btn-icon--low-contrast": props.variant === Variant.lowContrast,
      "btn-icon--high-contrast": props.variant === Variant.highContrast,
      "btn-icon--over-image": props.variant === Variant.overImage,
      dot: props.showDot,
    }));

    const ariaLabel = computed(() => attrs["aria-label"] ?? attrs["ariaLabel"] ?? props.title);

    return {
      attrs,
      inlineStyles,
      cssClasses,
      ariaLabel,
      Variant,
    };
  },
};
</script>

<style lang="scss" scoped>
.btn-icon {
  --dotSize: 8px;
  --scale: var(--scaleCustom, 1);

  width: calc(var(--controlHeight) * var(--scale));
  height: auto; // overwrite Element plus css
  padding: 0; // overwrite Element plus css
  aspect-ratio: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: calc(var(--controlRadius) * var(--scale));
  cursor: pointer;
  color: var(--controlFontColor);
  transition: background-color 0.2s ease, color 0.2s ease;
  flex-shrink: 0;
  flex-grow: 0;

  @include outline;

  &--over-image {
    color: var(--controlFontColor);
    background-color: rgba(255, 255, 255, 0.7);
    border: var(--controlBorder);
    box-shadow: var(--shadow1);
    backdrop-filter: blur(2px);
    &:hover {
      background-color: rgba(237, 237, 237, 0.7);
    }
    &.disabled {
      color: var(--gray500);
      pointer-events: none;
      cursor: not-allowed;
    }
  }
  &--primary {
    color: var(--white);
    background-color: var(--greenPrimary);
    border: 0;
    &:hover {
      background-color: var(--green500);
    }
    &.disabled {
      color: var(--gray400);
      background-color: var(--grayTransparent50);
      pointer-events: none;
      cursor: not-allowed;
    }
  }
  &--high-contrast {
    color: var(--controlFontColor);
    background-color: var(--grayTransparent50);
    border: 0;
    &:hover {
      background-color: var(--grayTransparent100);
    }
    &.disabled {
      color: var(--gray400);
      pointer-events: none;
      cursor: not-allowed;
    }
  }
  &--medium-contrast {
    border: 0;
    background-color: rgba(0, 0, 0, 0);
    &:hover {
      background-color: var(--grayTransparent50);
      color: var(--controlFontColor);
    }
  }
  &--low-contrast {
    border: 0;
    background-color: rgba(0, 0, 0, 0);
    color: var(--gray200);
    &:hover {
      background-color: var(--grayTransparent50);
      color: var(--gray400);
    }
  }
  &:deep(.icon) {
    width: calc(var(--controlIconSize) * var(--scale));
    height: calc(var(--controlIconSize) * var(--scale));
  }
  &.dot {
    position: relative;
    &:after {
      content: "";
      display: block;
      width: var(--dotSize);
      aspect-ratio: 1;
      border: 2px solid var(--white);
      border-radius: 50%;
      background: var(--red4);
      position: absolute;
      top: 3px;
      right: 3px;
    }
  }
}
</style>
