<template>
  <div :class="{ gallery: true, 'multiple-images': images.length > 1 }" v-if="isOpen">
    <div class="gallery__content" v-if="htmlSettings">
      <div class="image-wrapper">
        <div class="image-wrapper__inner">
          <ImageComponent :image="currentImage" :settings="htmlSettings" />
        </div>
        <div class="metadata" v-if="!isPhone && !isTablet">
          <div class="metadata__left" v-if="currentMetaData || images.length > 1">
            <Tag v-for="(data, i) in currentMetaData" :key="i">{{ data }}</Tag>
          </div>
          <div class="metadata__middle" v-if="images.length > 1">
            <Tag> {{ currentPage + 1 }}/{{ images.length }} </Tag>
          </div>
        </div>
      </div>
    </div>

    <div v-if="images.length > 1" class="gallery__click-area gallery__click-area--previous" @click.stop="previous">
      <div class="gallery__control gallery__control--arrow gallery__control--arrow--previous">
        <ButtonIcon :icon="faChevronLeft" :scale="controlsScale" :title="translate('previous')" />
      </div>
    </div>
    <div v-if="images.length > 1" class="gallery__click-area gallery__click-area--next" @click.stop="next">
      <div class="gallery__control gallery__control--arrow gallery__control--arrow--next">
        <ButtonIcon :icon="faChevronRight" @click.stop="next" :scale="controlsScale" :title="translate('next')" />
      </div>
    </div>
    <div class="gallery__control gallery__control--close">
      <ButtonIcon :icon="faXmark" @click.stop="close" :scale="controlsScale" :title="translate('close')" />
    </div>

    <div class="metadata" v-if="isTablet || isPhone">
      <div class="metadata__left" v-if="currentMetaData || images.length > 1">
        <Tag v-for="(data, i) in currentMetaData" :key="i">{{ data }}</Tag>
      </div>
      <div class="metadata__middle" v-if="images.length > 1">
        <Tag> {{ currentPage + 1 }}/{{ images.length }} </Tag>
      </div>
    </div>

    <div class="images-list" v-if="images.length > 1">
      <template v-for="(image, i) in images" :key="i">
        <div :class="{ 'images-list__image': true, current: i === currentPage }" @click.stop="goTo(i)">
          <ImageComponent :image="image" :settings="ThumbnailSize" />
        </div>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Events } from "@/types";
import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import { allowBodyScroll, disableBodyScroll } from "@/services/bodyScrollDisable";
import { Image, ImageHtmlSettings, ImageSize } from "@/services/images";
import ButtonIcon from "../basics/ButtonIcon.vue";
import { faChevronLeft, faChevronRight, faXmark } from "@fortawesome/pro-solid-svg-icons";
import { usePagination, useScreenSize, useTranslation, useUrlAnchor } from "@/composables";
import { isArrowLeft, isArrowRight, isEscape } from "@/services/keyDownHelpers";
import Tag from "../basics/Tag.vue";
import ImageComponent from "./Image.vue";
import { goBackInHistory } from "@/router/helpers";

const { translate } = useTranslation("components.gallery");

const ThumbnailSize = ImageSize["310x233"];

type MetaData = Map<number, string[]>; // number is image index in media array

interface GallerySettings {
  media: Image[];
  index?: number;
  imageHtmlSettings?: ImageSize | ImageHtmlSettings;
  metaData?: MetaData;
}

// Controls size
const { isTablet, isPhone } = useScreenSize();
const controlsScale = computed(() => (isTablet.value || isPhone.value ? 1 : 1.5));

// Current state + settings
const images = ref<Image[]>([]);
const currentImage = computed(() => images.value[currentPage.value]);
const metaData = ref<MetaData>(new Map());
const currentMetaData = computed(() => metaData.value.get(currentPage.value));
const htmlSettings = ref<ImageSize | ImageHtmlSettings>();

// Open/close fullscreen gallery
const { addAnchorToUrl, currentAnchor } = useUrlAnchor();
const UrlAnchor = "gallery";
const isOpen = ref(false);
onMounted(() => window.eventBus.on(Events.openGallery, open));
onUnmounted(() => window.eventBus.off(Events.openGallery, open));
const open = (settings: GallerySettings) => {
  images.value = settings.media;
  metaData.value = settings.metaData ?? new Map();
  htmlSettings.value = settings.imageHtmlSettings ?? ImageSize["1600x1200"];
  currentPage.value = settings.index ?? 0;
  updateItemsCount(images.value.length);
  isOpen.value = true;
  disableBodyScroll();
  addAnchorToUrl(UrlAnchor);
};
const close = () => {
  isOpen.value = false;
  allowBodyScroll();
  goBackInHistory();
};
watch(currentAnchor, (newAnchor) => {
  // Handle history.back
  if (newAnchor !== UrlAnchor) {
    isOpen.value = false;
    allowBodyScroll();
  }
});

// Navigation
const { currentPage, previous, next, goTo, updateItemsCount } = usePagination({ itemsCount: 0 });

// Keys binding
onMounted(() => document.addEventListener("keydown", onKeyDown));
onUnmounted(() => document.removeEventListener("keydown", onKeyDown));
const onKeyDown = (e: KeyboardEvent): void => {
  if (!isOpen.value) {
    return;
  } else if (isEscape(e)) {
    close();
  } else if (isArrowLeft(e)) {
    previous();
  } else if (isArrowRight(e)) {
    next();
  }
};
</script>

<style lang="scss" scoped>
.gallery {
  --controlPadding: var(--spacing4x);
  --sideClickableAreaWidth: 150px;
  --imageRadius: var(--radius4x);
  --maxContentWidth: calc(100dvw - 2 * calc(var(--sideClickableAreaWidth)));
  --thumbnailHeight: 125px;
  --thumbnailsSpacing: var(--spacing4x);
  --sectionsGap: var(--spacing4x);
  --metadataSpacing: var(--spacing1x);
  --metadataHeight: 22px;
  --metadataSectionHeight: calc(var(--metadataHeight) + 2 * var(--metadataSpacing));
  --thumbnailsSectionHeight: calc(var(--thumbnailHeight) + 2 * var(--thumbnailsSpacing));

  background: var(--white);
  z-index: calc(var(--overlay-z-index) + 2);
  position: fixed;
  top: 0;
  left: 0;
  width: 100dvw;
  height: 100dvh;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: minmax(0, 1fr);
  align-items: center;
  justify-items: center;
  gap: var(--sectionsGap);
  padding-top: var(--sectionsGap);
  padding-bottom: var(--sectionsGap);

  &.multiple-images {
    grid-template-rows: minmax(0, 1fr) calc(var(--thumbnailHeight) + 2 * var(--thumbnailsSpacing));
    padding-bottom: 0;
  }

  @include for-size(md) {
    --controlPadding: var(--spacing2x);
    --imageRadius: 0;
    --maxContentWidth: 100dvw;
    --thumbnailHeight: 91px;
    --sectionsGap: 0;
    --metadataSpacing: var(--spacing2x);

    &.multiple-images {
      grid-template-rows: minmax(0, 1fr) minmax(0, var(--metadataSectionHeight)) minmax(0, var(--thumbnailsSectionHeight));
    }
  }

  &__content {
    max-width: min(var(--maxContentWidth), 100dvw);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    &:deep(picture) {
      max-height: 100%;
      max-width: 100%;
      display: block;
      aspect-ratio: var(--itemImageRatio);
      border-radius: var(--imageRadius);
    }
    &:deep(img) {
      max-width: 100%;
      max-height: 100%;
      height: auto;
      display: block;
      border-radius: var(--imageRadius);
    }
  }
  &__control {
    position: absolute;
    padding: var(--controlPadding);
    &--arrow {
      top: 50%;
      transform: translateY(-50%);
      padding: calc(var(--controlPadding) * 2);
      @include for-size(md) {
        padding: var(--controlPadding);
      }
      &--previous {
        left: 0;
      }
      &--next {
        right: 0;
      }
    }
    &--close {
      right: 0;
      top: 0;
    }
  }
  &__click-area {
    width: var(--sideClickableAreaWidth);
    position: absolute;
    top: 0;
    height: calc(100dvh - var(--thumbnailHeight) - 2 * var(--controlPadding) - var(--sectionsGap));
    cursor: pointer;
    &--previous {
      left: 0;
    }
    &--next {
      right: 0;
    }
    @include for-size(md) {
      height: calc(100dvh - var(--metadataSectionHeight) - var(--thumbnailsSectionHeight));
    }
  }
}
.image-wrapper {
  position: relative;
  max-height: 100%;
  aspect-ratio: var(--itemImageRatio);
  &__inner {
    height: auto;
  }
}
.images-list {
  max-width: 100dvw;
  overflow-y: auto;
  padding: var(--thumbnailsSpacing);
  flex-shrink: 0;
  gap: var(--spacing4x);
  display: flex;
  height: calc(var(--thumbnailHeight) + 2 * var(--controlPadding));
  @include for-size(md) {
    gap: var(--spacing2x);
  }
  &__image {
    position: relative;
    border-radius: var(--radius4x);
    box-shadow: 0 0 0 6px rgba(0, 0, 0, 0);
    transition: box-shadow 0.3s ease;
    flex-shrink: 0;
    height: 100%;
    &:after {
      content: "";
      @include cover;
      box-shadow: inset 0 0 0 1px var(--grayTransparent100);
      border-radius: var(--radius4x);
    }
    &:deep(img) {
      border-radius: var(--radius4x);
      height: 100%;
      display: block;
    }
    &.current {
      box-shadow: 0 0 0 6px var(--greenPrimary);
    }
    &:hover:not(.current) {
      box-shadow: 0 0 0 6px var(--grayTransparent100);
      cursor: pointer;
    }
  }
}
.metadata {
  width: 100%;
  max-width: var(--maxContentWidth);
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: var(--spacing4x);
  padding: var(--metadataSpacing);
  position: absolute;
  bottom: 0;
  &__left {
    display: flex;
    gap: var(--spacing2x);
    flex-wrap: wrap;
  }
  @include for-size(md) {
    position: static;
  }
}
</style>
