import { NavigationGuardNext, RouteLocationAsRelativeGeneric, RouteLocationNormalized, RouteLocationRaw } from "vue-router";
import { useUserStore, store as pinia } from "@/store";
import { CountryCode, CountryCode2LanguageCodeMap, FallbackCountryCode, FallbackLanguageCode, LanguageCode } from "@/services/translation";
import { getEnumKeyByValue, isStringInEnum } from "@/utilities";
import { checkUserOnInitialPageLoad, getUser, User, wasSomeoneEverLoggedIn } from "@/services/user";
import { createLoginLink, createRegistrationLink, LockingStateRouteName } from "@/router/routes";

const userStore = useUserStore(pinia);

export const isPublic = (route: RouteLocationNormalized): boolean => route.meta?.public === true;

export const createLocalizedRoute = (route: RouteLocationAsRelativeGeneric): RouteLocationRaw => {
  if (route.params === undefined || !route.params.countryCode) {
    route.params = route.params ?? {};
    route.params.countryCode = userStore.getCountryCode();
  }
  return route;
};

export const goBackInHistory = () => window.history.back();

export const getLocalizationObject = (route: RouteLocationNormalized): { language: LanguageCode; country: CountryCode } | undefined => {
  const countryCodeString = route.params.countryCode as string;
  if (isStringInEnum(countryCodeString, CountryCode)) {
    const countryCode = getEnumKeyByValue(countryCodeString, CountryCode);
    const languageCode = CountryCode2LanguageCodeMap[countryCode];
    return { language: languageCode, country: countryCode };
  } else if (route.meta.allowNoCountryCode === true) {
    return { language: FallbackLanguageCode, country: FallbackCountryCode };
  } else {
    return undefined;
  }
};

export const redirectToAuth = (route: RouteLocationNormalized, next: NavigationGuardNext) => {
  let redirect;
  if (wasSomeoneEverLoggedIn()) {
    const redirectQueryParam = route.fullPath;
    redirect = createLoginLink(redirectQueryParam);
  } else {
    redirect = createRegistrationLink();
  }
  return next(redirect);
};

export const getUserForRouter = async (): Promise<User | undefined> => {
  const user = await getUser();
  // no currently running authentication process or no token found
  if (user === undefined) {
    const newlyAuthenticatedUser = await checkUserOnInitialPageLoad(); // check if there is any token stored and possibly start authentication
    return newlyAuthenticatedUser === null ? undefined : newlyAuthenticatedUser;
  } else {
    return user;
  }
};

export const isRouteAccountSetup = (route: RouteLocationNormalized) => route.name === LockingStateRouteName.accountSetup;
