import { getLocalizationObject, getUserForRouter } from "@/router/helpers";
import { LanguageCode2DefaultCurrencyIdMap, LanguageId2CountryCodeMap, setCountry } from "@/services/translation";
import { useUserStore } from "@/store";
import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

export const setUserPreferredLocalization = async (route: RouteLocationNormalized, _: RouteLocationNormalized, next: NavigationGuardNext) => {
  const localization = getLocalizationObject(route);
  if (localization === undefined) {
    throw new Error("DEV: Should not happen. Some beforeEach() guard which checks localization in URL should be called prior this guard.");
  }
  const user = await getUserForRouter();
  if (user && user.data) {
    const userPreferredLanguageId = user.data.displayLanguageId;
    const userPreferredCountry = LanguageId2CountryCodeMap[userPreferredLanguageId];
    setCountry(userPreferredCountry);
    if (localization.country !== userPreferredCountry) {
      const routeWithPreferredCountry = {
        ...route,
        params: {
          ...route.params,
          countryCode: userPreferredCountry,
        },
      };
      return next(routeWithPreferredCountry);
    }
  } else {
    const preferredCurrencyId = LanguageCode2DefaultCurrencyIdMap[localization.language];
    const userStore = useUserStore();
    userStore.setCurrencyId(preferredCurrencyId);
  }

  return next();
};
