import { ItemStatus } from "@/services/repositories/collection/items";
import { revertMap } from "@/utilities";

export enum ItemStatusUrlString {
  InMyCollection = "collection",
  OnMarketplace = "marketplace",
  Archived = "history",
}

export const ItemStatusToUrlString: Record<ItemStatus, ItemStatusUrlString> = {
  [ItemStatus.InMyCollection]: ItemStatusUrlString.InMyCollection,
  [ItemStatus.OnMarketplace]: ItemStatusUrlString.OnMarketplace,
  [ItemStatus.Archived]: ItemStatusUrlString.Archived,
};

export const UrlStringToItemStatus = revertMap(ItemStatusToUrlString);
