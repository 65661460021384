import { Tag } from "./Tag";
import { RawTagData } from "./Tags";

export function createTag(data: RawTagData): Tag {
  const tag = new Tag(data.tag_id, data.label);

  if (data.count) {
    tag.setCount(data.count);
  }

  return tag;
}
