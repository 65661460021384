import { useUserStore } from "@/store";

export function formatNumber(number: number): string {
  const userStore = useUserStore();
  const countryCode = userStore.getCountryCode();
  return new Intl.NumberFormat(countryCode).format(number);
}

/**
 * Make sure min is always lesser than max (or equal).
 * Meaning if min > max, this method returns [ max, min ].
 * If min or max is undefined do not make any changes.
 */
type RangeBoundary = number | undefined;
export function forceAscRangeOrder(min: RangeBoundary, max: RangeBoundary): [RangeBoundary, RangeBoundary] {
  if (min !== undefined && max !== undefined) {
    return min < max ? [min, max] : [max, min];
  } else {
    return [min, max];
  }
}

/**
 * Round given number to given number of decimal places.
 */
export function roundToDecimals(value: number, decimals: number): number {
  const factor = Math.pow(10, decimals);
  return Math.round(value * factor) / factor;
}
