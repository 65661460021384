import { API } from "@/services/api2";
import { Tag } from "./Tag";
import { createTag } from "./tagFactory";
import { AxiosResponse } from "axios";

export interface RawTagData {
  tag_id: number;
  label: string;
  count?: number;
}

const url = import.meta.env.VITE_API_COLLECTOR_URL + "collection/item-tag";

export const Tags = {
  MIN_LABEL_LENGTH: 1,
  MAX_LABEL_LENGTH: 30,
  MIN_STR_LENGTH_FOR_AUTOCOMPLETE: 1,

  getForItem: async function (itemId: number): Promise<Tag[]> {
    return API.get<RawTagData[]>(url + "/" + itemId).then((response) => {
      return createTagsArrayFromRawData(response.data);
    });
  },

  search: async function (query: string): Promise<Tag[]> {
    return API.get<RawTagData[]>(url + "/search", { q: query }).then((response) => {
      return createTagsArrayFromRawData(response.data);
    });
  },

  add: async function (itemId: number, label: string): Promise<Tag[]> {
    return API.post<RawTagData[]>(url + "/" + itemId, {}, { label: label }).then((response) => {
      return createTagsArrayFromRawData(response.data);
    });
  },

  remove: async function (itemId: number, tag: Tag): Promise<AxiosResponse> {
    return API.delete(url + "/" + itemId, {}, { tag_id: tag.getId() });
  },
};

function createTagsArrayFromRawData(data: RawTagData[]): Tag[] {
  return data.map((rawData: RawTagData) => createTag(rawData));
}
