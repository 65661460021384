<template>
  <Dialog ref="dialog" class="feedback-dialog">
    <div class="feedback-dialog__icon">
      <Icon :icon="icon" />
      <div class="feedback-dialog__icon__marker">
        <Icon :icon="faCheck" />
      </div>
    </div>
    <p class="feedback-dialog__text" v-html="text" />
    <template #footer>
      <Button @click="close" :is-full-width="true">
        {{ btnText }}
      </Button>
    </template>
  </Dialog>
</template>

<script lang="ts">
import { Events } from "@/types";
import { ref } from "vue";
import { onMounted, onUnmounted } from "vue";
import { defineAsyncComponent } from "vue";
import { TypeIcon } from "./Icon.vue";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";

const Dialog = defineAsyncComponent(() => import("@/components/basics/Dialog.vue"));
const Button = defineAsyncComponent(() => import("./Button.vue"));
const Icon = defineAsyncComponent(() => import("./Icon.vue"));

export type DialogFeedbackSettings = {
  icon: TypeIcon;
  text: string;
  btnText: string;
};

export default {
  components: {
    Button,
    Icon,
    Dialog,
  },
  setup() {
    onMounted(() => window.eventBus.on(Events.showFeedbackDialog, show));
    onUnmounted(() => window.eventBus.off(Events.showFeedbackDialog, show));

    const dialog = ref<InstanceType<typeof Dialog> | null>(null);
    const open = () => dialog.value?.open();
    const close = () => dialog.value?.close();

    const icon = ref<TypeIcon>();
    const text = ref<string>();
    const btnText = ref<string>();

    const show = (settings: DialogFeedbackSettings) => {
      icon.value = settings.icon;
      text.value = settings.text;
      btnText.value = settings.btnText;
      open();
    };

    return {
      faCheck,
      dialog,
      close,
      icon,
      text,
      btnText,
    };
  },
};
</script>

<style lang="scss">
.feedback-dialog {
  --dialogWidth: min(300px, 90vw);

  @include for-size(sm) {
    --dialogWidth: 100dvw;
  }
}
</style>

<style lang="scss" scoped>
.feedback-dialog {
  &__icon {
    width: 162px;
    height: 160px;
    position: relative;
    padding: var(--spacing4x) var(--spacing3x) var(--spacing4x) var(--spacing3x);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--gray900);
    margin: 0 auto;
    &__marker {
      position: absolute;
      top: 0;
      right: 0;
      background-color: var(--greenPrimary);
      color: var(--white);
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 11px;
      border-radius: 50%;
    }
  }
  &__text {
    color: var(--black);
    font-size: 22px;
    font-weight: 700;
    margin: var(--spacing4x) 0 var(--spacing2x) 0;
    text-align: center;
  }
}
</style>
