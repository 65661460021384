import { RouteRecordRaw } from "vue-router";
import { AppRouteName } from "./app";
import { RouteLocationRaw } from "vue-router";
import { gettext } from "@/services/translation";
import { createLocalizedRoute } from "@/router/helpers";
import { Layout } from "@/router/routeMeta";

export enum LockingStateRouteName {
  registration = "registrationNew",
  registrationEmailVerified = "registrationEmailVerified",
  login = "login",
  passwordRestore = "forgottenPassword",
  facebookSsoCallback = "facebookSsoCallback",
  accountSetup = "accountSetup",
}

export const lockingStateRoutes: RouteRecordRaw[] = [
  /**
   * Login
   */

  {
    path: "login",
    components: { mainContent: () => import("@/views/lockingStates/Login.vue") },
    name: LockingStateRouteName.login,
    meta: {
      public: true,
      title: () => gettext.$gettext("Přihlášení"),
      layout: Layout.LockingState,
      redirectWhenLoggedIn: { name: AppRouteName.dashboard },
    },
  },

  /**
   * Registration
   */

  {
    path: "sign-in",
    children: [
      {
        path: "",
        name: LockingStateRouteName.registration,
        components: { mainContent: () => import("@/views/lockingStates/Registration.vue") },
        meta: {
          public: true,
          title: () => gettext.$gettext("Nová registrace"),
          layout: Layout.LockingState,
          redirectWhenLoggedIn: { name: AppRouteName.dashboard },
        },
      },
      {
        name: LockingStateRouteName.registrationEmailVerified,
        path: "email-verification/:hash",
        components: { mainContent: () => import("@/views/lockingStates/EmailVerified.vue") },
        meta: {
          public: true,
          title: () => gettext.$gettext("Ověření e-mailu"),
          layout: Layout.LockingState,
          redirectWhenLoggedIn: { name: AppRouteName.dashboard },
        },
      },
      {
        name: LockingStateRouteName.accountSetup,
        path: "completion",
        components: { mainContent: () => import("@/views/lockingStates/AccountSetup.vue") },
        meta: {
          title: () => gettext.$gettext("Přizpůsob si svůj profil"),
          layout: Layout.LockingState,
        },
      },
    ],
  },

  /**
   * Password restore
   */

  {
    path: "password-reset",
    children: [
      {
        path: "",
        name: LockingStateRouteName.passwordRestore,
        components: { mainContent: () => import("@/views/lockingStates/PasswordRestoreRequest.vue") },
        meta: {
          public: true,
          title: () => gettext.$gettext("Obnova hesla"),
          layout: Layout.LockingState,
          redirectWhenLoggedIn: { name: AppRouteName.dashboard },
        },
      },
    ],
  },
  {
    path: "password-reset/:hash",
    components: { mainContent: () => import("@/views/lockingStates/PasswordRestore.vue") },
    meta: {
      public: true,
      title: () => gettext.$gettext("Obnova hesla"),
      layout: Layout.LockingState,
      redirectWhenLoggedIn: { name: AppRouteName.dashboard },
    },
  },
];

export const createRegistrationLink = (): RouteLocationRaw => createLocalizedRoute({ name: LockingStateRouteName.registration });

export const createLoginLink = (redirectAfterLogin?: string): RouteLocationRaw =>
  createLocalizedRoute({
    name: LockingStateRouteName.login,
    query: {
      redirect_url: redirectAfterLogin ?? undefined,
    },
  });

export const createPasswordRestoreLink = (): RouteLocationRaw => createLocalizedRoute({ name: LockingStateRouteName.passwordRestore });

export const createAccountSetupLink = (): RouteLocationRaw => createLocalizedRoute({ name: LockingStateRouteName.accountSetup });
