import { MetaTags } from "@/services/metaTags";
import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

export const setTitle = async (route: RouteLocationNormalized, _: RouteLocationNormalized, next: NavigationGuardNext) => {
  const title = route.meta.title;
  if (typeof title === "string") {
    MetaTags.setTitle(title);
  } else if (typeof title === "function") {
    MetaTags.setTitle(title());
  }

  return next();
};
