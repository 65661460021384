import { MetaTags } from "@/services/metaTags";
import { CountryCode, CountryCode2LanguageCodeMap } from "@/services/translation";
import { getEnumKeyByValue } from "@/utilities";
import { RouteLocationNormalized, useRouter } from "vue-router";

export const setLinkAlternate = async (route: RouteLocationNormalized) => {
  const domain = `${window.location.protocol}//${window.location.host}`;
  const alternateLinks = Object.values(CountryCode).map((country) => {
    const lang = CountryCode2LanguageCodeMap[getEnumKeyByValue(country, CountryCode)];
    if (!lang) {
      throw new Error("DEV: Should not happen.");
    }

    const alternateRoute = { ...route, params: { ...route.params, countryCode: country } };
    const router = useRouter();
    return {
      lang,
      url: domain + router.resolve(alternateRoute).href,
    };
  });
  MetaTags.setLinkAlternate(alternateLinks);
};
