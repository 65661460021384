export default {
  auth: {
    layout: {
      login: "Prihlásenie",
      newRegistration: "Registrácia",
      closeBtnTitle: "Zavrieť",
    },
    sso: {
      loginFailed: "Prihlásenie sa nepodarilo.",
      loginInProgress: "Kontrolujem údaje...",
      noToken: "Nepodarilo sa získať používateľský token.",
      googleLoginCreationFailed: "Nepodarilo sa vytvoriť Google prihlásenie. Máte povolené vytváranie cookies?",
    },
    login: {
      separator: "alebo",
      placeholderPassword: "Heslo",
      placeholderEmail: "E-mail",
      lostPasswordLink: "Zabudol si heslo?",
      loginButton: "Prihlásiť sa",
      loginButtonInProgress: "Prihlasujem...",
      passwordNotValid: "Vyplň heslo.",
      emailNotValid: "Neexistujúca e-mailová adresa.",
    },
    registration: {
      separator: "alebo",
      emailPlaceholder: "E-mail",
      passwordPlaceholder: "Heslo",
      registrationButton: "Registrovať sa",
      registrationButtonInProgress: "Vytváram účet...",
      emailNotValid: "Vyplň správne svoju e-mailovú adresu.",
      thankYou1: "Ďakujem za tvoju registráciu! Na e-mail",
      thankYou2: "sme odoslali odkaz na jej dokončenie.",
      newsletterLabel: "Informujte ma e-mailom o novinkách zo sveta zberateľstva.",
    },
    emailVerification: {
      okText: "Tvoja e-mailová adresa bola úspešne overená.",
      okCta: "Prihlásiť",
      inProgress: "Overujem...",
      invalidText: "Tento odkaz už bohužiaľ nie je platný.",
    },
    passwordRestore: {
      fillEmail: "Zadajte e-mailovú adresu, ku ktorej ste stratili heslo.",
      emailPlaceholder: "E-mail",
      requestBtn: "Odoslať",
      requestBtnInProgress: "Kontrolujem e-mail",
      requestSubmitted: "Na e-mail <strong>{ email }</strong> sme odoslali odkaz na obnovenie hesla.",
      restored: "Nové heslo bolo nastavené.",
      restoredCta: "Prihlásiť",
      invalidUrl: "Bohužiaľ, tento odkaz už nie je platný. Vygenerujte si prosím odkaz znova.",
      invalidUrlBtn: "Vygenerovať",
      hashCheckInProgress: "Kontrolujem adresu...",
      passwordPlaceholder: "Nové heslo",
      restoreBtn: "Nastaviť nové heslo",
      restoreBtnInProgress: "Nastavujem...",
    },
  },
};
