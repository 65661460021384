import { MetaTags } from "@/services/metaTags";
import { FallbackCountryCode } from "@/services/translation";
import { RouteLocationNormalized } from "vue-router";

export const setLinkCanonical = async (route: RouteLocationNormalized) => {
  let url = window.location.href;
  if (route.fullPath === "/") {
    url += FallbackCountryCode;
  }
  MetaTags.setLinkCanonical(url);
};
