import { useVersion } from "@/composables/version";
import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

export const checkVersion = async (_: RouteLocationNormalized, __: RouteLocationNormalized, next: NavigationGuardNext) => {
  const { isLatest } = useVersion();
  if (!isLatest.value) {
    window.location.reload();
  }
  return next();
};
