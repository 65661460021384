<template>
  <NotificationBar v-if="!isLatest" color="green">
    <div class="version-bar">
      <div class="version-bar__icon desktop-only">
        <Icon :icon="faSparkles" />
      </div>
      <p class="version-bar__text" v-html="translate('versionBar.text')" />
      <button class="btn btn--secondary" @click="router.go(0)">
        <span class="desktop-only">{{ translate("versionBar.btnDesktop") }}</span>
        <span class="mobile-only">{{ translate("versionBar.btnMobile") }}</span>
      </button>
    </div>
  </NotificationBar>

  <transition name="fade-500">
    <div v-if="isAppInitialized && layout !== undefined" :class="{ 'page-wrapper': true, 'with-notification': isSomeNotificationVisible }">
      <AppLayout v-if="layout === Layout.App" />
      <PublicLayout v-else-if="layout === Layout.Public" />
      <LockingState v-else-if="layout === Layout.LockingState" />
    </div>

    <div v-else class="loader">
      <img src="/images/logo-c-color.svg" />
    </div>
  </transition>

  <GalleryFullscreen />
  <DialogConfirmation />
  <DialogFeedback />
  <overlay-message />
</template>

<script setup lang="ts">
import OverlayMessage from "@/components/OverlayMessage.vue";
import GalleryFullscreen from "@/components/generics/GalleryFullscreen.vue";
import { TokenNotValidException, getUser } from "@/services/user";
import { computed, defineAsyncComponent, onMounted, onUnmounted, ref } from "vue";
import { isEscape } from "./services/keyDownHelpers";
import { Events } from "./types";
import { watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useTranslation } from "./composables";
import { useVersion } from "./composables/version";
import Icon from "@/components/basics/Icon.vue";
import { faSparkles } from "@fortawesome/pro-solid-svg-icons";
import NotificationBar from "@/components/NotificationBar.vue";
import { useAppState } from "@/store/appState";
import DialogConfirmation from "@/components/basics/DialogConfirmation.vue";
import DialogFeedback from "./components/basics/DialogFeedback.vue";
import { Layout } from "@/router/routeMeta";

const { translate } = useTranslation();

const layout = ref<Layout>();

onMounted(() => {
  window.addEventListener("keyup", onKeyUp);
});

onUnmounted(() => {
  window.removeEventListener("keyup", onKeyUp);
});

const AppLayout = defineAsyncComponent(() => import("@/components/layouts/app/Layout.vue"));
const PublicLayout = defineAsyncComponent(() => import("@/components/layouts/public/Public.vue"));
const LockingState = defineAsyncComponent(() => import("@/components/layouts/LockingState.vue"));

const onKeyUp = (e: KeyboardEvent) => {
  if (isEscape(e)) {
    window.eventBus.emit(Events.keyEscape);
  }
};

const route = useRoute();
const router = useRouter();

watch(route, async (to) => {
  const defaultLayout = to.meta.layout ?? Layout.Public;
  const loggedInLayout = to.meta.layoutLoggedIn;
  if (loggedInLayout !== undefined) {
    try {
      const user = await getUser();
      layout.value = user ? loggedInLayout : defaultLayout;
    } catch (e: unknown) {
      if (e instanceof TokenNotValidException) {
        // It's okay, don't throw error, we can fallback to layout for not logged in users.
        layout.value = defaultLayout;
      } else {
        throw e;
      }
    }
  } else {
    layout.value = defaultLayout;
  }
});

const appStateStore = useAppState();
appStateStore.startupUserCheck();
const isAppInitialized = computed(() => appStateStore.isAppInitialized());

// Latest bundle version check
const { isLatest, checkIsLatest, logVersionIntoConsole } = useVersion();
const checkInterval = 900000; // 900000 === 15 minutes
onMounted(() => setInterval(() => checkIsLatest(), checkInterval));
logVersionIntoConsole();

const isSomeNotificationVisible = computed(() => !isLatest.value);
</script>

<style lang="scss" scoped>
.page-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  @include for-size(md) {
    min-height: 100dvh;
  }

  &.with-notification {
    padding-top: var(--notificationBarHeight);
  }
}
.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    display: block;
    width: 100px;
    max-width: 90%;
    animation: loading 3s ease-in-out infinite;
    opacity: 0.9;
  }
}
.version-bar {
  display: flex;
  align-items: center;
  gap: var(--spacing2x);
  &__icon {
    width: 20px;
    height: 20px;
    color: var(--greenPrimary);
  }
  &__text {
    margin: 0 var(--spacing2x) 0 0;
  }
}

@keyframes loading {
  0% {
    transform: rotateY(0);
  }
  100% {
    transform: rotateY(360deg);
  }
}
</style>
