import { API, TokenAdd } from "@/services/api2";
import { getCookie, setCookie } from "@/services/cookies";
import { IndexedDb } from "@/services/indexedDb";
import { Facebook } from "./FacebookSso";
import { logout as googleLogout } from "./GoogleLoginService";
import { User } from "./User";
import { AccountType, ApiResponse_ThirdPartyLoginAuthentication, LoginProvider, RawUserEssentialsData, Role, ThirdPartyLoginStatus } from "./internalUserTypes";
import { AxiosError } from "axios";
import { createDashboardLink, createHomepageLink, createAccountSetupLink, router } from "@/router";
import { getUserData } from "./apiEndpointsAccount";
import { AuthenticationException, TokenNotValidException, UserNotEstablishedException } from "./userExceptions";
import { LoginEvent, trackEvent } from "@/services/googleAnalytics";
import { addTokenToUrl, catch401 } from "../api2/apiHelpers";
import { useUserStore, store as pinia } from "@/store";
import { disallowBrowserTracking, trackNewRegistrationEvent } from "@/services/marketingHelpers";
import { Events } from "@/types";

const userStore = useUserStore(pinia);

type Credentials = { email: string; password: string } | { token: string };

type AuthenticationPromise = Promise<User> | null;

const AuthStatusOk = 1;
const AuthStatusTokenNotFound = 3;
type ApiResponse_Authentication_error = {
  status: 2 | typeof AuthStatusTokenNotFound | 4 | 5 | 6 | 7 | 8; // We don't care about other statuses then "token not found".
  message: string;
};
type ApiResponse_Authentication =
  | {
      status: typeof AuthStatusOk;
      token: string;
      user: RawUserEssentialsData;
    }
  | ApiResponse_Authentication_error;

const BASE_URL = "user/";
const PROFILE_BASE_URL = "profile/";

/** Authentication API call cache. */
let authentication: AuthenticationPromise = null;

/** If cookie with this name is present, it means someone was already loggedin in this exact browser. */
const PreviousLoginCookieName = "dsr_swli";

/** IndexedDb token key. */
const DatabaseTokenKey = "userToken";

/**
 * Looks for browser saved user-token. If found, tries to verify it through API.
 */
export async function checkUserOnInitialPageLoad(): Promise<AuthenticationPromise> {
  if (authentication === null) {
    const token = await IndexedDb.get(DatabaseTokenKey);
    if (token) {
      return authenticate({ token });
    } else {
      return Promise.resolve(null);
    }
  } else {
    return authentication;
  }
}

/**
 * Requests User data from API in exchange for (email,password)|(token).
 */
async function authenticate(credentials: Credentials): Promise<AuthenticationPromise> {
  if (authentication === null) {
    const authUrl = BASE_URL + "authenticate";
    authentication = API.postPublic<ApiResponse_Authentication>(authUrl, {}, credentials, TokenAdd.Never)
      .then((response) => {
        const status = response.data.status;
        if (status === AuthStatusOk) {
          const token = response.data.token;
          const userData = response.data.user;
          if (userData.role === Role.admin) {
            disallowBrowserTracking();
          }
          return establishUserForThisSession(token, userData);
        }

        return Promise.reject(response.data); // awful... we need to rework BE for login.
      })
      .catch((error: UserNotEstablishedException | AxiosError | ApiResponse_Authentication_error) => {
        deleteUserToken();

        if (error instanceof UserNotEstablishedException) {
          const user = userStore.get();
          if (!user) {
            throw new Error("DEV: Should not happen. User should be logged-in even when UserNotEstablishedException occurs.");
          }

          const redirect = createAccountSetupLink();
          router.push(redirect);
          return user;
        } else if (error instanceof AxiosError) {
          throw error;
        } else {
          // Error sent in response.data ... awful... see above
          if (error.status === AuthStatusTokenNotFound) {
            throw new TokenNotValidException(); // We don't want any message here.
          } else {
            throw new AuthenticationException(error.message);
          }
        }
      });
  }

  return authentication;
}

/**
 * Login via given email and password => login form.
 */
export async function login(email: string, password: string): Promise<AuthenticationPromise> {
  authentication = null;
  return authenticate({ email, password });
}

/**
 * Establishes user for current browser session.
 * Creates User entity, stores user token in browser,
 */
export async function establishUserForThisSession(token: string, userData: RawUserEssentialsData): Promise<User> {
  storeUserToken(token);
  markLoginInBrowser();
  const user = createUser(userData, token);
  trackEvent(new LoginEvent(user.id));
  userStore.login(user);
  return loadUserSettings(user);
}

export async function ssoLogin(token: string, provider: LoginProvider, redirectUrl?: string): Promise<void> {
  return thirdPartyLogin(token, false, provider).then((response: ApiResponse_ThirdPartyLoginAuthentication) => {
    if (response.status === ThirdPartyLoginStatus.ok) {
      if (response.isFirstLogin) {
        let accountType;
        if (provider === LoginProvider.facebook) {
          accountType = AccountType.facebook;
        } else if (provider === LoginProvider.google) {
          accountType = AccountType.google;
        } else {
          throw new Error("DEV: Should not happen.");
        }
        const userId = response.user.id;
        trackNewRegistrationEvent(userId, accountType);
      }

      return establishUserForThisSession(response.token, response.user)
        .then(() => {
          userStore.saveSsoAccessToken(token);
          router.push(redirectUrl ?? createDashboardLink());
        })
        .catch((e: unknown) => {
          if (e instanceof UserNotEstablishedException) {
            const redirect = createAccountSetupLink();
            router.push(redirect);
          } else {
            throw e;
          }
        });
    } else {
      window.eventBus.emit(Events.toastError, response.message);
    }
  });
}

/** @deprecated */
export async function thirdPartyLogin(token: string, registerNewsletter: boolean, provider: LoginProvider): Promise<ApiResponse_ThirdPartyLoginAuthentication> {
  const url = BASE_URL + "third-party-login";
  const data = {
    token,
    provider,
    newsletter: registerNewsletter ? 1 : 0,
  };

  return API.postPublic<ApiResponse_ThirdPartyLoginAuthentication>(url, {}, data).then((response) => response.data);
}

/**
 * Returns currently logged in user or undefined if there is none.
 * Waits for currently running authentication (if there is any).
 */
export async function getUser(): Promise<User | undefined> {
  await authentication;
  return userStore.get();
}

/**
 * Returns currently logged in user or throws error.
 * Do not wait for possible authentication in progress.
 * @throws NoUserException
 */
export function requireUser(): User {
  const user = userStore.get();
  if (!user) {
    throw new NoUserException("User requested. However no user is logged in.");
  }

  return user;
}

export class NoUserException extends Error {}

/**
 * Logs out user. Redirects to login page.
 */
export async function logout(invalidateInApi = true): Promise<void> {
  const user = userStore.get();
  if (user) {
    if (user.isFacebookAccount()) {
      Facebook.logout();
    } else if (user.isGoogleAccount()) {
      googleLogout();
    }

    if (invalidateInApi) {
      const url = addTokenToUrl(PROFILE_BASE_URL + "logout");
      await API.put(url).catch(catch401);
    }

    deleteUserToken();
    userStore.logout();
    router.push(createHomepageLink());
  }

  authentication = null;
}

/**
 * Helper function which creates User entity.
 */
function createUser(data: RawUserEssentialsData, token: string): User {
  const ShowPricesValue = 1;
  const shouldBePricesVisible = data.showPrices === ShowPricesValue;

  const user = new User(data.id, data.email, data.type, data.name);
  user.setImage(data.image);
  user.setShowPrices(shouldBePricesVisible);
  user.setToken(token);

  return user;
}

async function loadUserSettings(user: User): Promise<User> {
  user.data = await getUserData();
  userStore.setCurrencyId(user.data.displayCurrency.id);
  return user;
}

/**
 * Helper function for setting "someone was logged in" flag.
 */
function markLoginInBrowser(): void {
  const expiration = new Date();
  expiration.setFullYear(expiration.getFullYear() + 1);
  setCookie(PreviousLoginCookieName, "1", expiration.toUTCString(), "/");
}

export function wasSomeoneEverLoggedIn(): boolean {
  const cookie = getCookie(PreviousLoginCookieName);
  return cookie !== null;
}

export function resetAuthentication(): void {
  authentication = null;
}

/**
 * Helper - stores user token in browser.
 */
function storeUserToken(token: string): void {
  IndexedDb.set(DatabaseTokenKey, token);
}

/**
 * Helper - removes browser-stored user token.
 */
function deleteUserToken(): void {
  IndexedDb.delete(DatabaseTokenKey);
}
